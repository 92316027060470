<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/campaign`"
                title="Campaign"
                :columns="columns"
                routerpath="/crm/campaign/addcampaign"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      edit: '',
      add: '',
      canDelete: '',

      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          },
          hidden: true
        },
        {
          label: 'Campaign No',
          field: 'campaignno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Campaign No'
          }
        },
        {
          label: 'Campaign Name',
          field: 'name',
          type: 'dropdown',
          url: 'getCampaigmaster',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Campaign Name'
          }
        },
        {
          label: 'Start Date',
          field: 'startdate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Start Date'
          }
        },
        {
          label: 'End Date',
          field: 'enddate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search End Date'
          }
        },
        {
          label: 'Enter By',
          field: 'enterby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Enter Date',
          field: 'created_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Modified By',
          field: 'modifiedby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${
          this.$route.params.id ? 'Edit Campaign' : 'Add Campaign'
        }`,
        submitRouterPath: '/crm/campaign',
        gridForm: true,
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/getcampaignById`,
        url: `${baseApi}/campaign`,
        inputFields: [
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          },
          {
            label: 'Modified Date',
            field: 'enterdatetime',
            filterOptions: {
              enabled: true,
              placeholder: 'Search Date'
            }
          }
        ]
      }
    }
  },
  mounted () {
    const userData = JSON.parse(localStorage.getItem('UserRights'))
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      userData.map((item) => {
        if (item.modulename == 'Campaign') {
          this.edit = item.edit
          this.add = item.add
          this.canDelete = item.delete
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push('/')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
